<template>
    <v-container fluid>
        <template>
            <v-row>
                <v-col cols="12" class="pb-1 pt-0">
                    <v-breadcrumbs :items="itemsBreadcrumbs" class="pl-0 pt-0 pb-1">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                            :to="item.href"
                            :disabled="item.disabled"
                            >
                            {{ item.text.toUpperCase() }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="pt-1">
                    <span class="headline font-weight-medium">
                        {{ account.bank.name }} - {{ account.type }} {{ account.currency }}
                    </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="pt-0">
                    <v-card>
                        <v-card-text class="pt-2 pb-2">
                            <v-container>
                                <v-row>
                                    <v-col cols="3">
                                        <span class="account-label">Saldo disponible</span>
                                        <span class="account-subtitle">
                                            {{ account.balance | currency(simbol_currency) }}
                                        </span>
                                    </v-col>
                                    <v-col cols="4">
                                        <span class="account-label">Número de Cuenta</span>
                                        <span class="account-subtitle">{{ account.number_account }}</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <span class="account-label">Código de Cuenta Interbancaria</span>
                                        <span class="account-subtitle">{{ account.cci }}</span>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="h-100">
                <v-col cols="12" class="h-100">
                    <v-card class="h-100">
                        <v-data-table
                            :headers="headers"
                            :items="movements"
                            :items-per-page="5"
                            class="elevation-1 w-table table-crud"
                            height="46vh"
                        >
                            <template v-slot:top>
                                <span  class="ml-3 pt-2 pb-4 subtitle-1 font-weight-bold w-title-table">
                                    Todos los movimientos
                                    <v-btn v-if="verifyPermission('cuentas.movement')"
                                        @click="handleAddMovement"
                                        dark 
                                        class="float-right mr-3" 
                                        color="#023145">
                                        Nuevo
                                    </v-btn>
                                </span>
                                
                                <v-divider></v-divider>
                            </template>

                            <template v-slot:item.date_operation="{item}">
                                <span>{{ item.date_operation | moment('dddd, D MMMM YYYY') }}</span>
                            </template>

                            <template v-slot:item.amount="{item}">
                                <span :class="item.type_operation.toLowerCase()">{{ item.amount | currency(simbol_currency) }}</span>
                            </template>

                            <template v-slot:item.actions="{item}">
                                <v-icon color="error" size="18" @click="handleDelete(item)">
                                    far fa-times-circle
                                </v-icon>
                            </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
        </template>

        <create-movement ref="createMovement"></create-movement>
    </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';

import CreateMovement from '../../../components/accounts/CreateMovement';

export default {
    name: 'DetailAccount',
    components: {
        CreateMovement
    },
    data () {
         return {
             itemsBreadcrumbs: [
                {
                    text: 'Finanzas',
                    disabled: true,
                    href: '',
                },
                {
                    text: 'Estado de cuenta',
                    disabled: false,
                    href: '/app/configuracion/cuentas-bancarias',
                }
            ],
            headers: [
                { text: 'FECHA', value: 'date_operation', sortable: false },
                { text: 'HORA', value: 'hour_operation', sortable: false },
                { text: 'TIPO DE OPERACIÓN', value: 'type_operation', sortable: false },
                { text: 'DESCRIPCIÓN', value: 'description', sortable: false },
                { text: 'MONTO', value: 'amount', sortable: false, align: 'end' },
                { text: '', value: 'actions', sortable: false, align: 'end' },
            ],
            detail: []
         }
    },
    computed: {
        ...mapState('accounts', [
            'account',
            'simbol_currency',
            'movements'
        ])
    },
    methods: {
        ...mapActions('accounts', [
            'getAccountsMovement',
            'deleteMovement'
        ]),
        handleAddMovement () {
            this.$refs.createMovement.show(this.account);
        },
        verifyPermission(permission) {
            return this.$hasPermision(permission)
        },
        handleDelete (movement) {
            this.$swal({
                title: "¿Desea eliminar?",
                text: "Confirma que desea eliminar el movimiento bancario",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Confirmar",
                cancelButtonText: "Cancelar",
            }).then( async (result) => {
                if(result.value){
                    if(result.value === true){
                        await this.deleteMovement(movement);
                    }
                }                    
            })
        },
    },
    created () {
        this.getAccountsMovement(this.account);
    }
}
</script>

<style scoped>
.account-label{
    display: block;
    color: #012d74;
    font-weight: 600;
    font-size: 1rem;
}

.account-subtitle{
    display: block;
    padding-top: 10px;
    font-weight: bold;
    font-size: 1.4rem;
    color: #000000;
}

.abono{
    font-weight: bold;
    color: #4CAF50;
}

.cargo{
    font-weight: bold;
    color: #F44336;
}
</style>