<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="400"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>NUEVO MOVIMIENTO</span>
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-menu
                            ref="menu_date"
                            v-model="menu_date"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    ref="date_operation"
                                    v-model="form.date_operation"
                                    label="Fecha de operación"
                                    readonly
                                    clearable
                                    v-on="on"
                                    outlined
                                    :rules="[() => !!form.date_operation || 'Es necesario ingresar una fecha']"
                                    hide-details="auto"
                                    required                               
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="form.date_operation" 
                                @input="menu_date = false" 
                                no-title 
                                show-current>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-menu
                            ref="menu_hour"
                            v-model="menu_hour"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="form.hour_operation"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                    v-model="form.hour_operation"
                                    label="Hora de operación"
                                    outlined
                                    readonly
                                    v-on="on"
                                    :rules="[() => !!form.hour_operation || 'Es necesario ingresar la hora de operación']"
                                    hide-details="auto"
                                    required
                                ></v-text-field>
                            </template>
                            <v-time-picker
                                v-if="menu_hour"
                                v-model="form.hour_operation"
                                format="24hr"
                                full-width
                                @click:minute="$refs.menu_hour.save(form.hour_operation)"
                            ></v-time-picker>
                            </v-menu>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            ref="number_operation"
                            label="Número de operación"
                            v-model="form.number_operation"
                            type="number"
                            outlined
                            :rules="[() => !!form.number_operation || 'Es necesario ingresar número de operación']"
                            hide-details="auto"
                            required>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-combobox
                            ref="type_operation"
                            v-model="form.type_operation"
                            label="Tipo de operación"
                            :items="types"
                            outlined
                            :rules="[() => !!form.type_operation || 'Es necesario seleccionar un tipo']"
                            hide-details="auto"
                            required>
                        </v-combobox>
                    </v-col>
                </v-row>
                <!--
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Cuenta Destino"
                            v-model="form.account_destination"
                            outlined
                            hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            label="Nombres"
                            v-model="form.name_destination"
                            outlined
                            hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
                -->
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            ref="amount"
                            label="Monto"
                            v-model="form.amount"
                            type="number"
                            outlined
                            :prefix="simbol_currency"
                            :rules="[() => !!form.amount || 'Es necesario seleccionar el monto']"
                            hide-details="auto"
                            required>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                            v-model="form.description"
                            rows="3"
                            label="Descripción"
                            hide-details
                            outlined>
                        </v-textarea>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3 form-buttons">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="success" block height="40" 
                            @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'CreateMovement',
    data () {
        return {
            drawer: false,
            menu_date: false,
            menu_hour: false,
            types: ['ABONO', 'CARGO'],
            form: {
                account_id: null,
                date_operation: null,
                hour_operation: null,
                number_operation: null,
                type_operation: null,
                account_destination: null,
                name_destination: null,
                description: null,
                amount: null,
                voucher_file: null,
                voucher_url: null
            }
        }
    },
    computed: {
        ...mapState('accounts', [
            'simbol_currency'
        ])
    },
    methods: {
        ...mapActions('accounts', [
            'storeMovement'
        ]),
        show(account) {
            this.clearForm ();
            this.form.account_id = account.id;
            this.drawer = !this.drawer;
        },
        clearForm () {
            this.form.account_id = null;
            this.form.date_operation = null;
            this.form.hour_operation = null;
            this.form.number_operation = null;
            this.form.type_operation = null;
            this.form.account_destination = null;
            this.form.name_destination = null;
            this.form.description = null;
            this.form.amount = null;
            this.form.voucher_file = null;
            this.form.voucher_url = null;
            this.clearErrors();
        },
        closeForm() {
            this.clearForm()
            this.drawer = false
        },
        handleCancel() {
            this.closeForm()
        },
        async handleStore () {
            
            if (!this.existErrors()) {
                console.log('guardar');
                await this.storeMovement(this.form);
                this.closeForm();
            }
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        }
    }
}
</script>

<style scoped>
.form-buttons{
    background-color: #ffffff;
}
</style>